<template>
        <div class="text-center justify-center py-6 customlightgray text-wrap">
            <v-row class="ma-auto pa-auto justify-center">
            <v-col cols="12" lg="12" class="pa-2 ma-auto">
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" sm="12" md="3" lg="2" class="ma-auto pa-0 text-center ">
                        <span style="font-size: 1.9rem !important;" class="headline custom-color-accent-text font-weight-bold ma-0 pa-0 ">
                            {{ request.reqCode }}
                        </span>
                    </v-col>
                
                    <v-col cols="12" sm="12" md="9" lg="10" class="mx-auto ma-0 pa-0 text-left">
                        <v-row class="pa-0 ma-0 ">
                            <v-col cols="6" sm="12" class="mx-auto px-auto ma-0 pa-0 text-right pr-1 pr-sm-0 text-sm-left">
                                <v-row class="ma-0 my-1 pa-0 text--disabled text-capitalize font-weight-medium">
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("requestor") }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("status") }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("Due Date") }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("Days Allowed") }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("Days Taken") }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ $t("extensiondays") }}</v-col>
                                </v-row>
                                
                            </v-col>
                            <v-col sm="12" class="d-none d-sm-block mx-auto px-auto ma-0 pa-0"><v-divider></v-divider></v-col>
                            <v-col cols="6" sm="12" class="ma-0 pa-0 text-sm-left  pl-1 pl-sm-0">
                                <v-row class="ma-0 my-1 pa-0 text--secondary text-capitalize font-weight-medium">
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{request.address.FirstName +" " + request.address.LastName }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ getlookup("ReqStatus", request.RequestStatus) }}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ calculateDueDate(request) | formatDate}}</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ getTypes(request.request_type).time_frame_to_deliver ? getTypes(request.request_type).time_frame_to_deliver:0 }} Days</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{ calculateDaysTaken(request.DateInfoComplete)}} Days</v-col>
                                    <v-col class="ma-0 pa-0" cols="12" sm="2">{{(request.ExtensionDays?moment().diff(moment(requeust.ExtensionDays), "days"):0)}} Days</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        
        </v-row>
    </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
    import moment from "moment";
    export default {
        props: ["request"],
        data() {
            return {

            }
        },
        computed: {
          ...mapGetters({
            getTypes:"getTypes"
          }),
            ...mapState("configuration", {
              configurations: "configurations",
            }),
        },
         methods: {
            getlookup(lookupname, val) {
            return this.configurations
                .filter(
                (filter) =>
                    filter.LookUpName === lookupname && filter.LookUpKey == val
                )
                .map((item) => {
                let status = "";
                status =
                    localStorage.getItem("language") === "en"
                    ? item.LookUpStringE
                    : item.LookUpStringF;
                return status;
                })[0];
            }, 
            calculateDaysTaken(DateInfoComplete){
                let normal_days=moment().diff(moment(DateInfoComplete), "days");
                return normal_days;
            },
            calculateDueDate( request ){
              let requestType = this.getTypes( request.request_type );
              let cal = 0;
              cal =+ requestType?.time_frame_to_deliver || 0;
              cal =+ request.HoldDays;
              cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
              cal =+ request.ExtensionDays;

              let DueDate= moment( request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
                if(!requestType.editable_due_date && !request.DateDue)
                    return this.checkHolidayWeekend(DueDate);
                else
                    return request.DateDue;
            },
            checkHolidayWeekend(date){
            let currentDay = moment(date).day();
            let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
            let totalHoliday = 0;
            if( holidays.length )
                totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
            if( currentDay === 6 || currentDay === 7 || totalHoliday ){
                if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
                if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
                this.checkHolidayWeekend(date);
            }
            return date;
            },
        },
    }
</script>
<style scoped>
.text-wrap{
    word-break: break-word;
}
</style>