<template>
    <div v-if="localRequest.address"> 
     
      <v-row class="align-center justify-center">
        <v-col cols="12" md="10" lg="8" class="ma-auto pb-5">
            <v-btn class="ma-auto pa-3 custom-color-accent" style="width:100%" depressed @click="print_request()"> 
              <span>{{$t("export")}}</span>
            </v-btn> 
        </v-col>
        <v-col cols="12" md="10" lg="8" class="" ref="content">
          <div class="my-border white" style="position:relative">
              <requestHeader :request="localRequest"></requestHeader>
              <v-divider></v-divider>
              <detailsTable :request="localRequest" :print="true"></detailsTable>
              <div class="overlay d-flex align-center justify-center">
                <v-progress-circular class="d-flex align-center"  v-if="load"
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
            </div>
        </v-col>
        <v-col cols="12" md="10" lg="8" class="ma-auto pt-5">
            <v-btn class="ma-auto pa-3 custom-color-accent" style="width:100%" depressed @click="print_request()"> 
              <span>{{$t("export")}}</span>
            </v-btn> 
        </v-col>
      </v-row>   
    </div>
</template>
<script>
import requestHeader from "../components/request/RequestHeader";
import detailsTable from "@/components/request/details";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    requestHeader,
    detailsTable
  },

  props: [

  ],

  data () {
    return {
      id: this.$route.params.id,
      load:false,
    }
  },

  mounted () {

  },

  async created () {
    if (this.localRequest) {
      await this.retriveRequestItem(this.$route.params.id);
    }
  },

  computed: {
    ...mapState("request", {
      localRequest: "requestItem"
    }),
  },

  methods: {
    print_request(){
      this.load=true;
      let canvasElement = document.createElement('canvas');
      html2canvas(this.$refs.content, { canvas: canvasElement }).then((canvas)=>{
        var doc = new jsPDF('p', 'pt','a4',true);
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = doc.internal.pageSize.getWidth(); 
        var pageHeight = doc.internal.pageSize.getHeight();
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var position = 0;
        var mx=10;
        doc.addImage(imgData, 'PNG', mx, position, imgWidth-mx*2, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          console.log(heightLeft,pageHeight,position);
          doc.addPage();
          doc.addImage(imgData, 'PNG', mx, position, imgWidth-mx*2, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( this.localRequest.reqCode+'.pdf');
        this.load=false;
      });
    },
    ...mapActions("request", ["retriveRequestItem"]),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>
