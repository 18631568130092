<template>
  <div class="text-wrap">

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
        <div class="elevation-0 pa-0 ma-0">
        <v-expansion-panel-header>{{ $t('Request Information') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="customlightgray pa-3 ma-0 my-border">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requestid') }}:</span>
                  </v-col>
                  <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                    <label>{{ request.reqCode }}</label>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                 <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0" >
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('atipofficer') }}: </span>
                  </v-col>
                    <v-col v-if="update_atip_officer && $checkPermission('Requests.Update') && $checkPermission('Requests.Assign')" cols="12" md="6" class="pa-0 ma-0">
                     <v-autocomplete  append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_atip_officer = false,modify=false"
                           @click:append-outer="save('update_atip_officer')" clearable clear-icon="mdi-delete" v-model="editedRequest.ATIPOfficerId" item-value="id" item-text="full_name"
                          :items="users" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                    </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <label v-if="request.assign" v-on="on" @click="modify = true , update_atip_officer=true" class="clickable text-capitalize">
                          {{ request.assign.full_name }}
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_atip_officer=true" class="clickable ">
                          ------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_atip_officer=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0" >
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('atipmanager') }}: </span>
                  </v-col>
                  <v-col v-if="update_atip_manager&& $checkPermission('Requests.Update') && $checkPermission('Requests.Assign')" cols="12" md="6" class="pa-0 ma-0">
                    <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_atip_manager = false,modify=false"
                           @click:append-outer="save('update_atip_manager')" clearable clear-icon="mdi-delete" v-model="editedRequest.Approver" item-value="id" item-text="full_name"
                          :items="users" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <label v-if="request.approver" v-on="on" @click="modify = true , update_atip_manager=true" class="clickable text-capitalize ">
                          {{ request.approver.full_name }}
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_atip_manager=true" class="clickable ">
                          ------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_atip_manager=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
              <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datereceived') }}:</span>
                  </v-col>
                  <v-col v-if="update_date_received&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      class="ma-0 pa-0"
                      hide-details
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_date_received = false,modify=false"
                           @click:append-outer="save('update_date_received')" clearable clear-icon="mdi-delete" 
                          dense
                          v-model="DateReceived"
                          class="ma-0 pa-0 pr-2 white my-border" flat solo
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker dark :max="nowDate" v-model="DateReceived"></v-date-picker>
                    </v-menu>
                  </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <label v-if="request.DateReceived" v-on="on" @click="modify = true , update_date_received=true" class="clickable ">
                           {{ request.DateReceived | formatDate }}
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_date_received=true" class="clickable ">
                          ------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_date_received=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                 <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('dateonrequest') }}: </span>
                  </v-col>
                   <v-col v-if="update_date_on_request&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      class="ma-0 pa-0"
                      hide-details
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_date_on_request = false,modify=false"
                           @click:append-outer="save('update_date_on_request')" clearable clear-icon="mdi-delete" 
                          dense
                          v-model="DateOnRequest"
                          class="ma-0 pa-0 pr-2 white my-border" flat solo
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker dark :max="nowDate" v-model="DateOnRequest"></v-date-picker>
                    </v-menu>
                  </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <label v-if="request.DateOnRequest" v-on="on" @click="modify = true , update_date_on_request=true" class="clickable ">
                           {{ request.DateOnRequest | formatDate }}
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_date_on_request=true" class="clickable ">
                          ------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_date_on_request=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="pa-0 ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datecompletereceived') }}: </span>
                  </v-col>
                  <v-col v-if="update_date_complete_received&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      class="ma-0 pa-0"
                      hide-details
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_date_complete_received = false,modify=false"
                           @click:append-outer="save('update_date_complete_received')" clearable clear-icon="mdi-delete" 
                          dense
                          v-model="DateInfoComplete"
                          class="ma-0 pa-0 pr-2 white my-border" flat solo
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker :max="nowDate" dark v-model="DateInfoComplete"></v-date-picker>
                    </v-menu>
                  </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <label v-if="request.DateInfoComplete" v-on="on" @click="modify = true , update_date_complete_received=true" class="clickable ">
                           {{ request.DateInfoComplete | formatDate }}
                        </label>
                        <label v-else v-on="on" @click="modify = true , update_date_complete_received=true" class="clickable ">
                          ------
                        </label>
                      </template>
                      <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_date_complete_received=true" icon color="teal">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
              <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                <v-row class="pa-0 ma-0">
                    <v-col cols="6" class="pa-0 ma-0">
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('datedue') }}: </span>
                    </v-col>
                    <v-col v-if="!getTypes( request.request_type ).editable_due_date" cols="6" class="pa-0 ma-0 text--secondary  ">
                          {{calculateDueDate(request)  | formatDate}}
                    </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-row class="pa-0 ma-0">
                        <v-col  v-if="update_due_date&& $checkPermission('Requests.Update')" cols="12" class="pa-0 ma-0">
                          <v-menu
                            :close-on-content-click="true"
                            :nudge-right="40"
                            class="ma-0 pa-0"
                            hide-details
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_due_date = false,modify=false"
                                @click:append-outer="save('update_due_date')" clearable clear-icon="mdi-delete" 
                                dense
                                v-model="DateDue"
                                class="ma-0 pa-0 pr-2 white my-border" flat solo
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker :min="nowDate" dark v-model="DateDue"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-else cols="12" class="pa-0 ma-0">
                            <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <label v-if="request.DateDue" v-on="on" @click="modify = true , update_due_date=true" class="clickable ">
                                {{ request.DateDue | formatDate }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_due_date=true" class="clickable ">
                                ------
                              </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_due_date=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                  </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="6" class="pa-0 ma-0">
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('status') }}: </span>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                      <label>{{ getlookupvalue("ReqStatus", request.RequestStatus) }}</label>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="6" class="pa-0 ma-0" >
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requestor') }}:</span>
                    </v-col>
                    <v-col v-if="update_requestor && $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                      <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_requestor = false,modify=false"
                            @click:append-outer="save('update_requestor')" clearable clear-icon="mdi-delete" v-model="editedRequest.RequestorId" item-value="id" :item-text="Requestor_fullname"
                            :items="address" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                    </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip  right>
                        <template  v-slot:activator="{ on }">
                          <label v-if="request.address.FirstName || request.address.LastName " v-on="on" @click="update_requestor = true,modify=true" class="clickable text-capitalize">
                            {{ request.address.FirstName + " " + request.address.LastName }}
                          </label>
                          <label v-else v-on="on" @click="update_requestor = true,modify=true" class="clickable ">
                            ------
                          </label>
                        </template>
                        <v-btn  v-if="$checkPermission('Requests.Update')" @click="update_requestor = true,modify=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
              </v-col>
               <v-col class="pa-0 ma-0" cols="12" sm="12" md="12">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="6" md="2" class="pa-0 ma-0" >
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('XRef') }}:</span>
                    </v-col>
                    <v-col v-if="update_XRef && $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                      <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_XRef = false,modify=false"
                            @click:append-outer="save('update_XRef')" clearable clear-icon="mdi-delete" v-model="editedRequest.XRef" item-value="reqCode" item-text="reqCode"
                            :items="requests" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                    </v-col>
                    <v-col v-else cols="6" class="pa-0 ma-0">
                      <v-tooltip  right>
                        <template  v-slot:activator="{ on }">
                          <label v-if="request.XRef" v-on="on" @click="update_XRef = true,modify=true" class="clickable ">
                            {{ request.XRef }}
                          </label>
                          <label v-else v-on="on" @click="update_XRef = true,modify=true" class="clickable ">
                            ------
                          </label>
                        </template>
                        <v-btn  v-if="$checkPermission('Requests.Update')" @click="update_XRef= true,modify=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
               </v-col>
              <v-col class="pa-0 ma-0" cols="12" sm="12" md="12">
                <v-row class="pa-0 ma-0">
                    <v-col cols="6" sm="4" md="2" class="pa-0 ma-0">
                      <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('summarytext') }}: </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="10" class="pa-0 ma-0">
                       <v-textarea v-if="update_summary&& $checkPermission('Requests.Update')" clearable clear-icon="mdi-delete" append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_summary = false,modify=false"
                           @click:append-outer="save('update_summary')" v-model="editedRequest.Summary" rows="1" auto-grow hide-details dense class="ma-0 pa-0 pr-2 white my-border" flat solo></v-textarea>
                       <v-tooltip v-else right>
                          <template v-slot:activator="{ on }">
                            <label v-if="request.Summary" v-on="on" @click="modify = true , update_summary=true" class="clickable ">
                              {{ request.Summary }}
                            </label>
                            <label v-else v-on="on" @click="modify = true , update_summary=true" class="clickable ">
                              ------
                            </label>
                          </template>
                          <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_summary=true" icon color="teal">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
        </div>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-0 pa-0 ma-0 white">
      <v-divider></v-divider>
        <div class="elevation-0 pa-0 ma-0">
          <v-expansion-panel-header>{{ $t('Requestor Information') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="customlightgray pa-3 ma-0 my-border">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('firstname') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.FirstName">
                            {{ request.address.FirstName }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('lastname') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.LastName">
                            {{ request.address.LastName }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('emailaddress') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary">
                        <label v-if="request.address.Email">
                            {{ request.address.Email }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>   
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('telephone') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.Phone1">
                            {{ request.address.Phone1 }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('cellphone') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.Phone2">
                            {{ request.address.Phone2 }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('streetnumber') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.AddrNum">
                            {{ request.address.AddrNum }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('street') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.Address">
                            {{ request.address.Address }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('city') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.City">
                            {{ request.address.City }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{$t("province")}}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                        <label v-if="request.address.Province">
                            {{ request.address.Province }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('postalcode') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-uppercase">
                        <label v-if="request.address.PostalCode">
                            {{ request.address.PostalCode }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" sm="12" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('country') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.Country">
                            {{ request.address.Country }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{$t("source")}}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.Source">
                            {{ request.address.Source }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('addresstype') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.AddressType">
                            {{ request.address.AddressType }}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('correspondencemethod') }}: </span>
                      </v-col>
                      <v-col v-if="update_correspondence_method&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_correspondence_method = false,modify=false"
                            @click:append-outer="save('update_correspondence_method')" clearable clear-icon="mdi-delete" v-model="editedRequest.RequestCorrespondPref"
                            :items="getLookup( 'Correspond' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <label v-if="request.RequestCorrespondPref" v-on="on" @click="modify = true , update_correspondence_method=true" class="clickable text-capitalize">
                                {{ getlookupvalue("Correspond", request.RequestCorrespondPref) }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_correspondence_method=true" class="clickable ">
                                ------
                              </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_correspondence_method=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row> 
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('preferredlanguage') }}: </span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary text-capitalize">
                        <label v-if="request.address.LangPref">
                            {{ request.address.LangPref=='en'?$t('english'):$t('french')}}
                          </label>
                          <label v-else>
                            ------
                          </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
      <v-divider></v-divider>
        <div class="elevation-0 pa-0 ma-0">
          <v-expansion-panel-header>{{ $t('originaltext') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="customlightgray pa-3 ma-0 my-border">
              <v-row class="pa-0 ma-0">
                <v-col class="d-flex pa-1" cols="12" sm="12">
                  
                  <v-textarea v-if="update_original_text&& $checkPermission('Requests.Update')" clearable clear-icon="mdi-delete" append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_original_text = false,modify=false"
                            @click:append-outer="save('update_original_text')" v-model="editedRequest.RequestText" rows="1" auto-grow hide-details dense class="ma-0 pa-0 pr-2 white my-border" flat solo></v-textarea>
                  <v-tooltip v-else right>
                    <template v-slot:activator="{ on }"><label v-if="request.RequestText" v-on="on" @click="modify = true , update_original_text=true" class="clickable ">
                        {{ request.RequestText }}
                      </label>
                      <label v-else v-on="on" @click="modify = true , update_original_text=true" class="clickable ">
                        ------
                      </label>
                    </template>
                    <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_original_text=true" icon color="teal">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
      <v-divider></v-divider>
        <div class="elevation-0 pa-0 ma-0">
          <v-expansion-panel-header>{{ $t('Additional Information') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="customlightgray pa-3 ma-0 my-border">
              <v-row class="pa-0 ma-0">
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requesttype') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary  ">
                        <label v-if="request.request_type">
                          {{$i18n.locale==='en'?getTypes(request.request_type).description_en:getTypes(request.request_type).description_fr}}
                        </label>
                        <label v-else>
                          ------
                        </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('securityclass') }}: </span>
                      </v-col>
                      <v-col v-if="update_security_class&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                          <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_security_class = false,modify=false"
                            @click:append-outer="save('update_security_class')" clearable clear-icon="mdi-delete"  v-model="editedRequest.SecurityClass"
                            :items="getLookup( 'Security' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <label v-if="request.SecurityClass" v-on="on" @click="modify = true , update_security_class=true" class="clickable text-capitalize">
                                  {{ getlookupvalue("Security", request.SecurityClass) }}
                                </label>
                                <label v-else v-on="on" @click="modify = true , update_security_class=true" class="clickable ">
                                  ------
                                </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_security_class=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('complexity') }}: </span>
                      </v-col>
                      <v-col v-if="update_complexity&& $checkPermission('Requests.Update')"  cols="12" md="6" class="pa-0 ma-0">
                          <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_complexity = false,modify=false"
                            @click:append-outer="save('update_complexity')" clearable clear-icon="mdi-delete" v-model="editedRequest.Complexity"
                            :items="getLookup( 'Complexity' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <label v-if="request.Complexity" v-on="on" @click="modify= true , update_complexity=true" class="clickable text-capitalize">
                                  {{ getlookupvalue("Complexity", request.Complexity) }}
                                </label>
                                <label v-else v-on="on" @click="modify= true , update_complexity=true" class="clickable ">
                                  ------
                                </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify= true , update_complexity=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('category') }}: </span>
                      </v-col>
                      <v-col v-if="update_category&& $checkPermission('Requests.Update')"  cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete v-if="update_category" append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_category = false,modify=false"
                            @click:append-outer="save('update_category')" clearable clear-icon="mdi-delete" v-model="editedRequest.Category"
                            :items="getLookup( 'Category' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0"> 
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                  <label v-if="request.Category" v-on="on" @click="modify= true , update_category=true" class="clickable text-capitalize">
                                    {{ getlookupvalue("Category", request.Category) }}
                                  </label>
                                <label v-else v-on="on" @click="modify= true , update_category=true" class="clickable ">
                                  ------
                                </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify= true , update_category=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('clauseset') }}: </span>
                      </v-col>
                    <v-col v-if="update_clause_set&& $checkPermission('Requests.Update')"  cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_clause_set = false,modify=false"
                            @click:append-outer="save('update_clause_set')" clearable clear-icon="mdi-delete" v-model="editedRequest.ClauseSet"
                            :items="getLookup( 'ClauseSet' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                    </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0">
                            <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                  <label v-if="request.ClauseSet" v-on="on" @click="modify = true , update_clause_set=true" class="clickable text-capitalize">
                                    {{ getlookupvalue("ClauseSet", request.ClauseSet) }}
                                  </label>
                                <label v-else v-on="on" @click="modify = true , update_clause_set=true" class="clickable ">
                                  ------
                                </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_clause_set=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                  <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('accessmethod') }}: </span>
                      </v-col>
                      <v-col v-if="update_access_method&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_access_method = false,modify=false"
                            @click:append-outer="save('update_access_method')" clearable clear-icon="mdi-delete" v-model="editedRequest.accessMethod"
                            :items="getLookup( 'AccMethod' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col cols="6" v-else class="pa-0 ma-0">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <label v-if="request.accessMethod" v-on="on" @click="modify = true , update_access_method=true" class="clickable text-capitalize">
                                  {{ getlookupvalue("AccMethod", request.accessMethod) }}
                                </label>
                                <label v-else v-on="on" @click="modify = true , update_access_method=true" class="clickable ">
                                  ------
                                </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_access_method=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col> 
              </v-row>
            </div>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>


      <v-expansion-panel  class="elevation-0 pa-0 ma-0 white">
      <v-divider></v-divider>
        <div class="elevation-0 pa-0 ma-0">
          <v-expansion-panel-header>{{ $t('Closing Information') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="customlightgray pa-3 ma-0 my-border">
              <v-row class="pa-0 ma-0">
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('Date Closed') }}:</span>
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-0 text--secondary">
                        <label v-if="request.DateOfClosure">
                          {{ request.DateOfClosure.split(' ')[0] }}
                        </label>
                        <label v-else>
                          ------
                        </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>

                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('releaseflag') }}: </span>
                      </v-col>
                      <v-col v-if="update_release_flag&& $checkPermission('Requests.Update')"  cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_release_flag = false,modify=false"
                            @click:append-outer="save('update_release_flag')" clearable clear-icon="mdi-delete" v-model="editedRequest.releaseFlag"
                            :items="getLookup( 'ReleaseFlg' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col cols="6" v-else class="pa-0 ma-0"><v-tooltip right>
                            <template v-slot:activator="{ on }">
                                  <label v-if="request.releaseFlag" v-on="on" @click="modify = true , update_release_flag=true" class="clickable text-capitalize">
                                    {{ getlookupvalue("ReleaseFlg", request.releaseFlag) }}
                                  </label>
                                  <label v-else v-on="on" @click="modify = true , update_release_flag=true" class="clickable ">
                                    ------
                                  </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_release_flag=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requestdisposition') }}: </span>
                      </v-col>
                      <v-col v-if="update_request_disposition&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_request_disposition = false,modify=false"
                            @click:append-outer="save('update_request_disposition')" clearable clear-icon="mdi-delete" v-model="editedRequest.RequestDisposition" 
                            :items="getLookup( 'Dispose' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                      <v-col v-else cols="6" class="pa-0 ma-0">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                  <label v-if="request.RequestDisposition" v-on="on" @click="modify = true , update_request_disposition=true" class="clickable text-capitalize">
                                    {{ getlookupvalue("Dispose", request.RequestDisposition) }}
                                  </label>
                                  <label v-else v-on="on" @click="modify = true , update_request_disposition=true" class="clickable ">
                                    ------
                                  </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_request_disposition=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <v-col class="d-flex pa-1" cols="12" sm="6" md="4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="6" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('latereason') }}: </span>
                      </v-col>
                      <v-col v-if="update_late_reason&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                        <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_late_reason = false,modify=false"
                            @click:append-outer="save('update_late_reason')" clearable clear-icon="mdi-delete" v-model="editedRequest.LateReason" 
                            :items="getLookup( 'LateReason' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                      </v-col>
                        <v-col v-else cols="6" class="pa-0 ma-0">
                            <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                  <label v-if="request.LateReason" v-on="on" @click="modify = true , update_late_reason=true" class="clickable text-capitalize">
                                    {{ getlookupvalue("LateReason", request.LateReason) }}
                                  </label>
                                  <label v-else v-on="on" @click="modify = true , update_late_reason=true" class="clickable ">
                                    ------
                                  </label>
                            </template>
                            <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_late_reason=true" icon color="teal">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-tooltip>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>

              </v-row>
            </div>
              <v-row class="pa-0 ma-0">
                <v-col class="d-flex pa-0 mt-3 my-border" cols="12" sm="12">
                    <v-row class="pa-0 ma-0 pa-3 customlightgray">
                      <v-col cols="6" sm="4" md="2" class="pa-0 ma-0">
                        <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('clauses') }}: </span>
                      </v-col>
                      <v-col cols="12" sm="12" md="10" class="pa-0 ma-0 secondary--text">
                          <label v-if="clauses" class="text-capitalize">
                            clause1,clause2,clause3
                          </label>
                          <label v-else>
                            ------
                        </label>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                </v-col>
                <span class="pa-3 ma-0 ">{{ $t('Documents Statistics') }}</span> 
                
                <v-col cols="12" class="pa-0 ma-0 my-border">
                  <v-row class="px-3 ma-0 customlightgray">
                      <v-col cols="8" md="12" class="mx-0 px-0 ma-0 pa-0  text-sm-left">
                        <v-row class="ma-0 my-1 pa-0 text--disabled text-capitalize font-weight-medium">
                          <v-col cols="12" md="6" class="ma-0 pa-0">
                            <v-row class="pa-0 ma-0">
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('docsprocessed') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pdfproduced') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesreviewed') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesexcluded') }}</v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6" class="ma-0 pa-0">
                            <v-row class="pa-0 ma-0">
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesexempt') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesduplicate') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesnotrelevant') }}</v-col>
                              <v-col class="ma-0 pa-0" cols="12" md="3">{{ $t('pagesnotregistered') }}</v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="12" class="d-none d-md-block mx-0 px-0 ma-0 pa-0"><v-divider></v-divider></v-col>
                      <v-col cols="3" md="12" class="ma-0 pa-0 text-md-left">
                          <v-row class="ma-0 my-1 pa-0 text--secondary text-capitalize font-weight-medium">
                            <v-col cols="12" md="6" class="ma-0 pa-0">
                              <v-row class="pa-0 ma-0">
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ getStatistics.processed }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ request.release.reduce((sum,i)=>sum + i.total_pages,0) }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{  getStatistics.pagesprocessed }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{getStatistics.excluded}}</v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="ma-0 pa-0">
                              <v-row class="pa-0 ma-0">
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ getStatistics.exempt }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ getStatistics.duplicate }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ getStatistics.notrelevant }}</v-col>
                                <v-col class="ma-0 pa-0" cols="12" md="3">{{ getStatistics.notRegistered }}</v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                </v-col>


              </v-row>
          </v-expansion-panel-content>
        <v-divider></v-divider>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>


</template>
<script>

import {mapState, mapActions, mapGetters} from 'vuex';
import moment from "moment";

export default {
  props: ["request","print"],
  data() {
    return {
      editedRequest:{},
      panel: [0, 1, 2],
      nowDate: new moment().toISOString(),
      modify: false,
      clauses:null,
      DateReceived:null,
      DateOnRequest:null,
      DateInfoComplete:null,
      DateDue:null,
      newRequestor: "",
      updateType: "",
      update_requestor:false,
      update_atip_officer:false,
      update_atip_manager:false,
      update_date_received:false,
      update_date_on_request:false,
      update_date_complete_received:false,
      update_due_date:false,
      update_summary:false,
      update_correspondence_method:false,
      update_original_text:false,
      update_security_class:false,
      update_complexity:false,
      update_category:false,
      update_clause_set:false,
      update_process_method:false,
      update_release_flag:false,
      update_access_method:false,
      update_request_disposition:false,
      update_late_reason:false,
      update_late_reason_other:false,
      update_XRef:false
    }
  },
  mounted(){
    if(this.print)
      this.panel=[0,1,2,3,4];
  },
  computed: {
    ...mapState("consultation", {
      releasedoc: "releasedoc",
    }),
    ...mapGetters({
      getTypes:"getTypes"
    }),
    ...mapGetters("documents", {
      //getPreview: "getPreview",
      getStatistics: "getStatistics",
      notes: "getNotesByPage"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
    ...mapState("address", {
      address: "addresses"
    }),
    ...mapState({
      types: "types",
      organizations: "organizations",
      users: "users"
    }),
     ...mapState('request',{
      'requests':'requests'
    }),
  },
  async created() {
    await this.clearAddressesFilter();
    await this.retrieveAddresses();
    if(this.request){
      Object.assign(this.editedRequest, this.request);
      this.editedRequest.request_type = this.request.request_type;
      this.DateReceived = moment(this.editedRequest.DateReceived).format("YYYY-MM-DD");
      this.DateOnRequest = moment(this.editedRequest.DateOnRequest).format("YYYY-MM-DD");
      this.DateInfoComplete = moment(this.editedRequest.DateInfoComplete).format("YYYY-MM-DD");
      this.DateDue = moment(this.editedRequest.DateDue).format("YYYY-MM-DD");
    }
  },
  watch:{
    request(val){
      Object.assign(this.editedRequest, val);
      this.DateReceived = moment(this.editedRequest.DateReceived).format("YYYY-MM-DD");
      this.DateOnRequest = moment(this.editedRequest.DateOnRequest).format("YYYY-MM-DD");  
      this.DateInfoComplete = moment(this.editedRequest.DateInfoComplete).format("YYYY-MM-DD");
      this.DateDue = moment(this.editedRequest.DateDue).format("YYYY-MM-DD");
    },
    modify(val){
      if(val){
        if(this.$checkPermission("Requests.Update")){
          Object.assign(this.editedRequest, this.request);
          this.editedRequest.request_type = this.request.request_type;
        }
        else{
          this.$root.$emit("callAlert", {
            text: this.$t("notallowed"),
            type: "dark",
            alert: true
          });
          this.modify=false;
        }
      }
    }
  },
  methods: {
    getlookupvalue(lookupname, val) {
      return this.configurations.filter(filter => filter.LookUpName === lookupname && filter.LookUpKey == val).map(item => {
        let status = "";
        status = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
        return status;
      })[0]
    },
    getLookup(column) {
      return this.configurations.filter(filter => filter.LookUpName === column).map(item => {
        let arr = [];
        arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return arr;
      })
    },
    save(/* obj */) {

      this.editedRequest.DateReceived = this.DateReceived;
      this.editedRequest.DateOnRequest = this.DateOnRequest;
      this.editedRequest.DateInfoComplete = this.DateInfoComplete;
      this.editedRequest.DateDue = this.DateDue;
      this.updateRequest(this.editedRequest).then(() => {
      this.$root.$emit("callAlert", {
          text: this.$t("Request Updated Successfully"),
          type: "success",
          alert: true
        });
      this.modify = false;
      this.update_requestor = false;
      this.update_atip_officer = false;
      this.update_atip_manager = false;
      this.update_date_received = false;
      this.update_date_on_request = false;
      this.update_date_complete_received = false;
      this.update_due_date = false;
      this.update_summary = false;
      this.update_correspondence_method = false;
      this.update_original_text = false;
      this.update_security_class = false;
      this.update_complexity = false;
      this.update_category = false;
      this.update_clause_set = false;
      this.update_process_method = false;
      this.update_release_flag = false;
      this.update_access_method = false;
      this.update_request_disposition = false;
      this.update_late_reason = false;
      this.update_late_reason_other = false;
      this.update_XRef=false;
      Object.assign(this.request,this.editedRequest)
      });
    },
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      return date;
    },

    Requestor_fullname: item => item.FirstName + ' ' + item.LastName,
    ...mapActions('address', ['retrieveAddresses','clearAddressesFilter']),
    ...mapActions("request", ["updateRequest"]),
    ...mapActions(["retriveTypes", "retriveUsers", "retriveOrganizations"])
  }
};
</script>
<style lang="scss" scoped>

.text-wrap{
    word-break: break-word;
}
.v-tooltip__content {

  background: rgba(0, 0, 0, 0);
  pointer-events: auto;
}
.v-expansion-panel::before{
  box-shadow:  0px 0px 0px 0px rgba(0, 0, 0, 0);;
}
</style>